import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/home' },
  {
    path: '/login',
    component: () => import('@/views/Login/Login.vue'),
    name: 'login'
  },
  {
    path: '/home',
    component: () => import('@/views/Home/Home.vue'),
    name: 'home',
    redirect: '/home/main',
    children: [
      {
        path: 'main',
        component: () => import('@/views/Main/Main.vue'),
        name: 'main'
      },
      {
        path: 'register',
        component: () => import('@/views/Register/Register.vue'),
        name: 'register'
      },
      {
        path: 'releaseInfor',
        component: () => import('@/views/ReleaseInfor/ReleaseInfor.vue'),
        name: 'releaseInfor'
      },
      {
        path: 'ArtDetails1',
        component: () => import('@/views/ArtDetails/ArtDetails1.vue'),
        name: 'ArtDetails1'
      },
      {
        path: 'ArtDetails2',
        component: () => import('@/views/ArtDetails/ArtDetails2.vue'),
        name: 'ArtDetails2'
      },
      {
        path: 'ArtDetails3',
        component: () => import('@/views/ArtDetails/ArtDetails3.vue'),
        name: 'ArtDetails3'
      },
      {
        path: 'ArtDetails4',
        component: () => import('@/views/ArtDetails/ArtDetails4.vue'),
        name: 'ArtDetails4'
      },
      {
        path: 'ArtDetails5',
        component: () => import('@/views/ArtDetails/ArtDetails5.vue'),
        name: 'ArtDetails5'
      },
      {
        path: 'ArtDetails6',
        component: () => import('@/views/ArtDetails/ArtDetails6.vue'),
        name: 'ArtDetails6'
      },
      {
        path: 'ArtDetails7',
        component: () => import('@/views/ArtDetails/ArtDetails7.vue'),
        name: 'ArtDetails7'
      },
      {
        path: 'ArtDetails8',
        component: () => import('@/views/ArtDetails/ArtDetails8.vue'),
        name: 'ArtDetails8'
      },
      {
        path: 'ArtDetails9',
        component: () => import('@/views/ArtDetails/ArtDetails9.vue'),
        name: 'ArtDetails9'
      },
      {
        path: 'ArtDetails10',
        component: () => import('@/views/ArtDetails/ArtDetails10.vue'),
        name: 'ArtDetails10'
      },
      {
        path: 'ArtDetails11',
        component: () => import('@/views/ArtDetails/ArtDetails11.vue'),
        name: 'ArtDetails11'
      },
      {
        path: 'ArtDetails12',
        component: () => import('@/views/ArtDetails/ArtDetails12.vue'),
        name: 'ArtDetails12'
      },
      {
        path: 'ArtDetails13',
        component: () => import('@/views/ArtDetails/ArtDetails13.vue'),
        name: 'ArtDetails13'
      },
      {
        path: 'ArtDetails14',
        component: () => import('@/views/ArtDetails/ArtDetails14.vue'),
        name: 'ArtDetails14'
      },
      {
        path: 'ArtDetails15',
        component: () => import('@/views/ArtDetails/ArtDetails15.vue'),
        name: 'ArtDetails15'
      },
      {
        path: 'ArtDetails16',
        component: () => import('@/views/ArtDetails/ArtDetails16.vue'),
        name: 'ArtDetails16'
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
